<template>
  
<section  class="uk-section uk-section-secondary uk-section-large keep">
    <div class="uk-container">
        <p class="uk-hidden@m"></p>
        <h1> <span class="no-print">Roadmap:</span>  {{roadmap.name}}</h1>
        <p class="uk-text-lead no-print">Mapa del desarrollo del proyecto {{roadmap.name}}</p>
        <div v-if="roadmap.entregables">
            <div v-if="Object.keys(roadmap.entregables).length > 0">
                <h3>Entregables</h3>
                <p>El proyecto cuenta con {{Object.keys(roadmap.entregables).length}} entregable<span v-if="Object.keys(roadmap.entregables).length > 1">s</span>: </p>
                <ol>
                    <li v-for="item,index in roadmap.entregables" uk-grid class="uk-child-width-1-2@s">
                        <div>
                            <div>{{item.desc}} - Entrega: <span v-if="new Date(parseInt(item.date)).toLocaleDateString() == 'Invalid Date'">En espera</span> <span v-else>{{new Date(parseInt(item.date)).toLocaleDateString()}}</span> </div>
                        </div>
                        <div class="no-print">
                            <a v-if="item.btntxt" :href="item.url" target="_blank" class="uk-button uk-button-primary uk-border-rounded">{{item.btntxt}}</a>
                            <a v-if="item.btn" :href="item.url" target="_blank" ><img :src="item.btn" style="width:135px" alt=""></a>
                        </div>
                    </li>
                </ol>
            <hr>
            </div>
        </div>
        <p class="uk-text-lead print-only">Resumen del proceso desarrollo del proyecto {{roadmap.name}}</p>
        <div uk-grid class="uk-child-with-1-2@s no-print" >
            <div>
                <p class="no-print">Las tarjetas de abajo representan las características del proyecto. </p>
                <p class="print-only">Las características presentadas abajo corresponden a las características del proyecto cuyo desarrollo ha sido COMPLETADO o se encuentran EN DESARROLLO. </p>
                <ul class="no-print" style="font-size:18px">
                    <li style="color:lightgreen">Los elementos verdes indican características desarrolladas y listas para usar</li>
                    <li style="color:gold">Los elementos amarillos indican características en desarrollo activo o esperando cambios</li>
                    <li >Los elementos grises indican características por desarrollar</li>
                </ul>
            </div>
            <div>
                <div v-if="roadmap.features" class="no-print">
                    <p class="uk-text-center"><button @click="print" class="uk-button uk-border-pill uk-button-primary uk-button-large">Imprimir Resumen del Roadmap</button></p>
                </div>
            </div>
        </div>
        <hr>
       

        <div uk-grid="masonry: true" class="uk-child-width-1-1 no-print">
            <div  v-for="feat, index in roadmap.features">
                <div :style="'background-color:'+getColor(feat.state)" class="uk-margin uk-card uk-card-default uk-background-muted uk-card-body uk-border-rounded">
                    <h3 style="color:black">{{Object.keys(roadmap.features).indexOf(index)+1}} - {{feat.name}}</h3>
                    <p style="color:black">{{feat.desc}}</p>
                    <div  class="uk-card uk-card-default uk-card-body uk-border-rounded">
                        <h3 style="color:black">Requerimientos</h3>
                        <div uk-grid class="uk-child-width-1-3@s">
                            <div v-for="sub,subID in feat.subitems" >
                                <div :style="'background-color:'+getColor(sub.state)" class="uk-card uk-card-default uk-card-body uk-border-rounded">
                                    <h4 style="color:black">{{Object.keys(feat.subitems).indexOf(subID)+1}}. {{sub.name}}</h4>
                                    <p style="color:black">{{sub.desc}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="print-only">
            <div v-for="feat, index in roadmap.features">
                <div style="page-break-after:always" v-if="feat.state != 'none' && feat.state">

                    <h3><span v-if="feat.state == 'dev'">EN DESARROLLO - </span><span v-if="feat.state == 'done'">COMPLETADO - </span>{{Object.keys(roadmap.features).indexOf(index)+1}} - {{feat.name}} </h3>
                    <p style="color:black">{{feat.desc}}</p>
                    <h4 style="color:black"> <b>Requerimientos</b> </h4>
                    <div class="uk-margin-medium-left" v-for="sub,subID in feat.subitems">
                        <div v-if="sub.state != 'none' && sub.state">
                            <h4 style="color:black"><span v-if="sub.state == 'dev'">EN DESARROLLO - </span><span v-if="sub.state == 'done'">COMPLETADO - </span>{{Object.keys(feat.subitems).indexOf(subID)+1}}. {{sub.name}}</h4>
                            <p class="uk-margin-medium-left" style="color:black">{{sub.desc}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import { ref } from '@vue/reactivity'
import { useRoute } from 'vue-router'
export default {
    setup(){
        const route = useRoute()
        const roadmap = ref({})
        if(route.params.id){
            firebase.database().ref('site/roadmaps/'+route.params.id).on('value',(s)=>{
                roadmap.value = s.toJSON()
            })
        }
        
        const getColor = (state)=>{
            switch (state) {
                case 'done':
                    return 'lightgreen';
                case 'dev':
                    return 'gold';
                default:
                    return 'lightgrey';
            }
        }
        const print = () => {window.print()}
        return {
            roadmap,
            getColor,
            print,
        }
    }
}
</script>
